.access {
    border-bottom: 1px solid $color-primary;

    .container {
        max-width: 787px;
        margin-bottom: 75px;

        @include mq('phablet', 'max') {
            margin-bottom: 45px;
        }
    }

    &__desc {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin: 26px 0;

        @include mq('phablet', 'max') {
            font-size: 14px;
        }
    }

    iframe {
        height: 412px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include mq('tablet', 'max') {
            height: 300px;
        }
    }
}