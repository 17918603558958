.section-inquiry {
  border-top: solid 1px $color-primary;
  border-bottom: solid 1px $color-primary;
  margin: 0 auto;

  .container {
    max-width: 940px;
  }

  .heading-title .heading-primary span {
    background-color: #f8fcfe;
  }
}

.inquiry-form {
  padding: 30px 0 45px 0;
  font-weight: 700;
  font-family: $font-secondary;

  @include mq("tablet") {
    padding: 0 0 60px 0;
    width: 90%;
    margin: 0 auto;
  }

  &__paragraph {
    font-size: 14px;
    margin-top: 30px;
    font-weight: 400;
  }

  &__custom-file-title {
    margin: 20px 0 10px;
  }

  @include mq("tablet-wide") {
    width: 85%;
  }

  &__title {
    font-size: 16px;
    line-height: 2;
    font-weight: 700;
    text-align: center;
    //   margin: 0 0 30px;
    color: $color-gray;
    padding-bottom: 65px;

    @include mq('phablet', 'max') {
      padding-bottom: 30px;
    }
  }

  .form-group {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    @include mq("tablet") {
      margin-bottom: 35px;
    }
  }

  .flex-start {
    align-items: flex-start;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @include mq("tablet") {
      margin-bottom: 15px;
      height: 100%;
      margin-bottom: 0;
    }
  }

  &__label,
  &__value {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    color: $color-gray;

    @include mq("tablet") {
      font-size: 18px;
    }
  }

  &__value,
  &__file-value {
    font-weight: 400;
  }

  &__state {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    background: $color-red;
    color: $color-white;

    @include mq("tablet") {
      font-size: 14px;
      height: 29px;
      line-height: 29px;
      padding: 0 8px;
    }

    &--any {
      color: $color-white;
      background-color: #0091d7;
      border: 1px solid #0091d7;
    }
  }

  &__radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .custom-control {
      flex: 0 1 45%;
      margin-bottom: 10px;

      @include mq("phablet") {
        flex: 1 1 auto;
        margin-bottom: 0;
      }
    }

    .custom-control-label {
      font-size: 18px;
    }
  }

  .form-control {
    height: 40px;
    border-radius: 0;
    border: solid 1px $color-light-gray;
    border-top: 2px solid $color-light-gray;
    font-size: 18px;
    font-weight: 400;

    &:focus {
      box-shadow: 0 0 0 1px rgba($color-black, 0.4);
    }

    &::placeholder {
      color: $color-light-gray;
    }
  }

  .short-width {
    width: 229px;
  }

  .custom-control-label::before {
    border: 2px solid $color-light-gray;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: $color-primary;
    background-color: $color-primary;
  }

  .custom-select {
    // background: url("../img/arrow-down.svg") no-repeat right 0.75rem center;
    // background-image: url(./img/arrow-down.svg);
    background-size: 22px 22px;
  }

  textarea.form-control {
    height: 250px;
    resize: none;
  }

  &__btn-wrapper {
    margin-top: 35px;
    text-align: center;
    display: flex;
    width: 300px;
    background-color: $color-primary;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: 52px;
    font-size: 18px;
    font-weight: bold;
    color: $color-white !important;
    border-radius: 5px;

    &:hover {
      opacity: 0.7;
    }

    @include mq("tablet") {
      margin-top: 70px;
    }

    img {
      width: 9px;
      position: absolute;
      right: 45%;

      @include mq('desktop', 'max') {
        right: 42%;
      }

      @include mq('tablet-port', 'max') {
        right: 40%;
      }

      @include mq('phablet', 'max') {
        right: 30%;
      }

      @media screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
        margin-top: -8px;
      }
    }
  }

  &__btn {
    background: $color-primary;
    color: $color-white;
    margin: 0 auto;
    width: 100%;
    height: 52px;
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    text-align: center;
    position: relative;



    @include mq("phablet") {
      width: 300px;
    }

    &:hover {
      text-decoration: none;
      color: $color-white;
      box-shadow: none;

      @include mq("tablet") {
        // background: rgba($color-yellow, 0.8);
      }
    }
  }

  &__confirm-btn {
    background: $color-primary !important;
  }
}

// send.php
.form-sended {
  border-top: solid 1px $color-primary;
  border-bottom: solid 1px $color-primary;

  &__wrapper {
    min-height: 60vh;
    text-align: center;
  }
}

.custom-file {
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-label {
  background: transparent;
  padding-left: 170px;
  font-weight: 700;
  line-height: 2;
  border: none;
  outline: 0;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 45px;
  // .label-text {
  //   position: relative;
  // }
}

.cross {
  color: $color-red;
  font-size: 6px;
  width: 15px;
  height: 15px;
  padding: 2px 3px 3px 3px;
  background-color: $color-red;
  position: absolute;
  z-index: 1000;
  top: 0;
  display: none;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px $color-black;

  @include mq("tablet", "max") {
    left: 98% !important;
  }
}

.selected {
  position: relative;
  top: 7px;
}

.custom-file-input:focus {
  outline: 0;
}

.custom-file-input:lang(ja)~.custom-file-label::after {
  content: "ファイルを選択";
  right: initial;
  left: 0;
  background: $color-white;
  border: 2px solid $color-light-gray;
  border-radius: 4px;
  font-weight: 700;
  line-height: 1.3;
  color: #000;
  height: 45px;
  padding: 9px 19px;
  width: 155px;
}

.err {
  color: red;
}

// .sign-cross {
//     position: absolute;
//     width: 10px;
//     height: 10px;
//     top: 0;
//     left: 59%;
// }