
.u-text--black {
    color: $color-black;
}

.ie {

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        padding-top: 10px !important;
    }
}