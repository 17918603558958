

body {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 1.5;
    background: $color-white;
}

section {
  
}
.padding {
    padding-top: 40px;
}
.container {
    display: block;
    max-width: 100%;

    @include mq('phablet') {
        padding: 0 25px;
    }

    @include mq('tablet-wide') {
        // max-width: 1054px;
        padding: 0 15px;
    }

    &--lg {
        max-width: 2060px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
    margin: 0;
}

%reset-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}