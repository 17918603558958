.footer {
    margin-top: 37px;
    text-align: center;
    color: $color-gray;

    @include mq('phablet', 'max') {
        margin-top: 30px;
    }

    &__top {
        margin-bottom: 30px;

        @include mq('phablet', 'max') {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__desc {
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: bold;

        @include mq('phablet', 'max') {
            font-size: 16px;
        }

        &--1 {
            font-size: 16px;
            font-weight: 500;

            @include mq('phablet', 'max') {
                font-size: 14px;
            }
        }

        &--2 {
            @include mq('phone-wide', 'max') {
                font-size: 12.5px;
            }

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                padding-top: 5px;
            }
        }
    }

    &__bottom {
        background-color: $color-primary;
        color: $color-white;
        padding: 13px 0;
    }



    //     padding: 40px 0 0;
    //     margin-bottom: 60px;
    //     border-top: 1px solid $color-primary;

    //     @include mq('phablet') {
    //         margin-bottom: 0;
    //     }

    //     &__address {
    //         line-height: 1.8;
    //         margin: 25px auto 30px;
    //         font-size: 10px;
    //         color: $color-primary;

    //         @include mq('tablet'){
    //             font-size: 16px;
    //         }
    //     }
    //     &__img {
    //         width: 140px;
    //     }


    //     &__copyright {
    //         background: $color-primary;
    //         font-size: 16px;
    //         padding: 20px 0;
    //     }
}