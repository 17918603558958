// font-family

$font-primary: 'YuGothic' , 'Yu Gothic' ,'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' ,  'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif;

$font-secondary: Arial,
"Helvetica Neue",
Helvetica,
sans-serif;

// colors 
$color-primary: #867BA9;
$color-black: #333333;
$color-white: #FFFFFF;
$color-gray: #666666;
$color-red: #C1272D;
$color-light-gray: #CCCCCC;


