.service {
    &__desc {
        font-size: 16px;
        line-height: 2;
        text-align: center;
        color: $color-gray;
        @include mq('phablet', 'max') {
            font-size: 13.5px;
        }
    }

    &__examples {
        max-width: 800px;
        margin-right: auto;
        margin-left: auto;

        img {
            width: 300px;
            margin-top: 20px;

            @include mq('tablet', 'max') {
                width: 100%;
            }

            @include mq('phone-wide', 'max') {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 80%;
            }
        }
    }

    .row {
        padding-top: 20px;

        &--1 {
            padding-top: 40px;
            @include mq('tablet') {
                padding-top: 90px;
            }
        }
    }

    &__heading {
        font-size: 18px;
        color: $color-gray;
        @include mq('phablet', 'max') {
            font-size: 16px;
        }
        // font-weight: bold;
        &:after {
            content: "";
            display: block;
            // margin: 0 auto; 
            width: 90%;
            padding-top: 10px;
            border-bottom: 1px solid #D3D3D3;
        }
    }

    table,
    th,
    td {
        border: 1px solid #CCCCCC;
    }

    table {
        width: 400px;

        @include mq('tablet-port', 'max') {
            width: 350px;
        }

        @include mq('tablet', 'max') {
            width: 100%;
        }
    }

    td {
        padding: 5px 10px;
        vertical-align: middle;
        font-size: 16px;
        color: $color-gray;
        z-index: 999;
        @include mq('phablet', 'max') {
            font-size: 14px;
        }
    }

    .bg-gray {
        background-color: rgba(185, 133, 233, 0.1);
    }

    &__list {
        display: table;
        margin: 0 auto;
        font-size: 16px;
        line-height: 1.8;
        color:#666;
        @include mq('phablet', 'max') {
            font-size: 14px;
        }
    }
}