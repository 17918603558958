.contact-us {
    background: #333333;
    border-top: 1px solid $color-gray;
    margin: 60px 0 0 0;
    text-align: center;

    @include mq('tablet', 'max') {
        margin: 40px 0 0 0;
    }

    .container {
        max-width: 600px;
        padding-top: 37px;
        padding-bottom: 37px;
        padding-left: 0;
        padding-right: 0;
        @include mq('phone-wide', 'max') {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &__title {
        color: $color-white;
        font-size: 29px;

        @include mq('tablet', 'max') {
            font-size: 26px;
        }

        @include mq('phablet', 'max') {
            font-size: 20px;
        }

        span {
            font-size: 16px;

            @include mq('tablet', 'max') {
                font-size: 14px;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        flex: 1;
        border: 1px solid #D3D3D3;
        padding: 15px 0;
        @include mq('tablet-small','max'){
            margin:0 20px;
        }
        @include mq('tablet-wide') {
            // margin-right: 30px;
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        @include mq('phablet') {
            align-items: center;
        }

        @include mq('tablet') {
            margin-top: 30px;
        }

        @include mq('tablet-wide') {
            margin-top: 40px;
        }
    }

    &__btn-wrapper {
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // margin-right: 15px;

        @include mq('tablet') {
            align-items: flex-start;
            flex-direction: row;
            align-items: center;
        }

        @include mq('tablet-wide') {
            justify-content: space-between;
            // align-items: center;
           
        }
    }

    &__phone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        // @include mq('tablet-port') {
        //     margin-bottom: 20px;
        // }

        @include mq('tablet') {
            justify-content: space-between;
            margin-bottom: 0;
        }

        .consultation {
            padding: 6px 5px;

            @include mq('phablet') {
                padding: 12px 10px;
            }

            &__text-big {
                @include fluid-type(400px, 768px, 20px, 33px);
            }

            &__text-small {
                @include fluid-type(400px, 768px, 13px, 21px);
            }
        }

        .btn-phone {
            // margin: 0 0 0 15px;
            text-decoration: none;

            @include mq('phablet') {
                // margin: 0 0 0 30px;
            }

            @include mq('tablet') {
                margin: 0 30px;
            }

            &__flex-wrapper {
                margin: 1px 0;

                @include mq('phablet') {
                    margin: 3px 0;
                }
            }

            &__info {
                font-size: 18px !important;
                color: $color-white;
                @include fluid-type(400px, 768px, 12px, 17px);

                &:hover {
                    color: #ddd;
                }
            }

            &__number {
                color: $color-white;
                font-weight: bold;
                @include fluid-type(400px, 768px, 19px, 30px);

                &:hover {
                    color: #ddd;
                }
            }

            img {
                width: 15px;

                @include mq('phablet') {
                    width: 25px;
                }
            }
        }
    }


    // .btn-mail--lg
    .btn-mail {
        border: 1px solid #D3D3D3;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            padding-top: 14px !important;
        }

        &__info {
            color: $color-white;
            @include fluid-type(400px, 768px, 14px, 18px);
            vertical-align: middle;

            &:hover {
                color: #ddd;
            }

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                vertical-align: baseline !important;
            }
        }

        // &__time {
        //     @include fluid-type(400px, 768px, 10px, 15px);
        // }

        img {
            width: 25px;
            vertical-align: middle;

            @include mq('phablet') {
                width: 30px;
            }
        }
    }

    &__small-img {
        flex: 0 1 20%;

        @media (min-width: 360px) {
            flex: 0 1 30%;
        }
    }

    &__img {
        flex: 0 1 22%;
        align-self: flex-end;
    }
}
.service-contact{
    margin:0;
}