.nav-icon {
    width: 36px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    @include mq('tablet','max'){
        // top:50%;
        // transform: translateY(-50%);
        width: 25px;
        // top:10px;
        right: 45px;
    }
    @include mq('phablet','max'){
        // top:55%;
        // transform: translateY(-50%);
        width: 25px;
        right:20px;
    }
    &:before,
    &:after,
   
    >span {
        content: '';
        display: block;
        background-color: $color-black;
        display: block;
        height: 4px;
        margin: 6px 0;
        transition: all .2s ease-in-out;
      @include mq('phablet','max'){
          height: 3px;
      }
    }

    &.is-opened {
        &:before {
            transform: translateY(10px) rotate(135deg);
        }

        &:after {
            @include mq('phablet','max'){
                transform: translateY(-8px) rotate(-133deg);
            }
            transform: translateY(-10px) rotate(-135deg);
        }

        &>span {
            transform: scale(0);
        }
    }
}