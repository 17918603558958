.header {
    &__bottom {
        padding: 10px 0;
        position: relative;
        border-top: solid 4px $color-primary;

        @include mq("bootstrap-lg") {
            padding: 30px 0 25px;
        }

        .container {
            @include mq("desktop") {
                padding: 0;
            }
        }
    }

    &__flex-container {
        display: flex;
        align-items: center;
        flex-direction: row;

        @include mq('bootstrap-lg', 'max') {
            // flex-direction: column;
        }
    }

    &__logo {
        text-decoration: none;
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
        margin-left: 15px;
        // margin-right: 40px;
        // margin-bottom: 20px;
        width: 28%;
        white-space: nowrap;
        display: flex;
        align-items: center;

        &:hover {
            opacity: 0.8;
            text-decoration: none;
            color: $color-primary;
        }

        @media only screen and (max-width: 1328px) and (min-width: 1248px) {
            margin-right: 0;
        }

        @include mq('desktop', 'max') {
            // margin-right: 10px;
            margin-left: 5px;
            font-size: 14px;
        }

        @include mq('tablet-wide', 'max') {
            font-size: 12px;

        }

        @include mq('bootstrap-lg', 'max') {
            width: 100%;
            margin-bottom: 0;
            font-size: 16px;
        }

        @include mq('phablet', 'max') {
            margin-left: 0;
        }

        @include mq('tablet-wide') {
            width: 45%;
        }

        a {

            &:hover {
                text-decoration: none;
                color: $color-primary;
            }
        }

        img {
            width: 180px;

            &:hover {
                opacity: 0.9;
            }

            @include mq('desktop', 'max') {
                width: 128px;
                margin: 0;
            }

        }
    }

    .japanese {
        font-size: 16px;

        @media only screen and (max-width: 1248px) and (min-width: 992px) {
            font-size: 12px;
        }
    }

    &__english {
        font-size: 14px;


        @media only screen and (max-width: 1248px) and (min-width: 992px) {
            font-size: 12px;
        }

    }

    .btn-phone {
        color: $color-gray;
        padding-left: 12px;

        @include mq('desktop', 'max') {
            padding-left: 8px;
        }

        @include mq('bootstrap-lg', 'max') {
            width: 50%;
            text-align: center;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
        }

        img {
            width: 20px;
            margin-right: 5px;

            @media only screen and (max-width: 1247px) and (min-width: 992px) {
                width: 15px;
            }

            @include mq('phablet', 'max') {
                width: 15px;
            }
        }
    }

    .btn-phone-top {
        font-size: 23px;
        font-weight: bold;

        @include mq('desktop', 'max') {
            font-size: 16px;
        }
    }

    .btn-phone-btm {
        font-size: 14px;

        @include mq('desktop', 'max') {
            font-size: 12px;
        }

        @include mq('bootstrap-lg', 'max') {
            display: flex;
            justify-content: center;
        }
    }

    .btn-mail {
        display: flex;
        background-color: $color-primary;
        padding: 12px 25px;
        border-radius: 5px;
        align-items: center;

        &:hover {
            background-color: $color-primary;
            color: $color-white;
            opacity: 0.8;
        }

        @include mq('desktop', 'max') {
            padding: 15px 18px;
        }

        @include mq('bootstrap-lg', 'max') {
            width: 30%;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
        }

        @include mq('tablet-small', 'max') {
            padding: 8px 18px;
            width: 50%;
        }

        img {
            width: 25px;
            margin-right: 10px;

            @include mq('phablet', 'max') {
                width: 20px;
            }
        }
    }

    .btn-mail-text {
        color: $color-white;
        font-weight: bold;
        font-size: 15px;

        @media only screen and (max-width: 1248px) and (min-width: 992px) {
            font-size: 12px;
        }

        @include mq('phablet', 'max') {
            font-size: 12px;
        }
    }

    &__btn-group {
        list-style: none;
        justify-content: flex-end;
        align-items: center;

        // width: 100%;
        @include mq('desktop') {
            margin-right: 30px;
        }

        @include mq('tablet-wide') {
            width: 100%;
        }



        @include mq('bootstrap-lg', 'max') {
            flex-direction: column;
            max-height: 0;
            // transition: all 0.25s ease;
            overflow: hidden;
            transition: visibility 0s, opacity 0.5s linear;
        }

        li {
            @include mq('bootstrap-lg', 'max') {
                text-align: left;
            }

            // text-align: center;
            &:first-child {
                @include mq('bootstrap-lg', 'max') {
                    border-top: solid 1px $color-gray;
                }
            }

            @include mq('bootstrap-lg', 'max') {
                width: 100%;
                border-bottom: solid 1px $color-gray;
                padding: 10px 0 10px 20px;
            }

        }

        a {
            text-decoration: none;
            color: $color-gray;
            padding-right: 29px;
            font-weight: 500;
            display: block;
            text-align: center;

            &:hover {
                color: $color-black;
            }

            @media only screen and (max-width: 1328px) and (min-width: 1248px) {
                padding-right: 19px;
            }

            @include mq('desktop', 'max') {
                padding-right: 16px;
            }

            @include mq('bootstrap-lg', 'max') {
                width: 100%;
                margin-bottom: 10px;
                text-align: left;
            }

        }

        @include mq("tablet") {
            display: flex;
        }

        @include mq("tablet-wide") {
            margin-top: 0;
        }

        &.is-opened {

            @include mq('bootstrap-lg', 'max') {
                max-height: 1000px;
                width: 100%;
                position: absolute;
                top: 0;
                background-color: #fff;
                z-index: 999;
                margin-top: 45px;
                left: 0;
                padding: 0;
                padding-bottom: 12px;
            }

            @include mq('phablet', 'max') {
                margin-top: 44px;
            }
        }
    }
}

.dropDown {
    &__links {
        padding: 0 10px;
        list-style: none;

        li {
            padding: 10px;
            border: none;

            &:first-child {
                border-top: none;
            }

            a {
                color: #000;
                text-align: left;
                padding-right: 0;
                margin-bottom: 0;
            }
        }
    }
}

@include mq("tablet") {
    .dropDown {
        &__parent {
            position: relative;

            &:hover {
                .dropDown__links {
                    visibility: visible;
                    opacity: 1;
                    transform: scaleY(1);
                    transition: transform 0.4s ease-out;
                    transform-origin: top;

                    &:hover {
                        visibility: visible;
                        // transition: transform 0.4s ease-out;
                        opacity: 1;
                    }

                    // transition: transform 0.4s ease-out;
                    // transform-origin: top;

                    // &:hover {
                    //   transform: scaleY(1);
                    // }

                    // li {
                    // }
                }
            }
        }

        &__links {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            left: 0;
            top: 78px;
            transition: all 0.4s ease-out;
            transform-origin: top;
            transform: scaleY(0);
            background-color: #fff;
            width: 220%;
            z-index: 5;
            @include mq('desktop','max'){
                width:300%;
                top:69px;
            }
            a {
                // color: #000;
                margin-bottom: 0;

                &:hover {
                    color: $color-primary !important;
                }
            }
        }
    }
}
