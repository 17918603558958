.heading-title {
  color: $color-gray;
  text-align: center;
  padding: 25px 0 5px;
  @include mq("phablet") {
    padding: 30px 0 25px;
  }
  @include mq("tablet") {
    padding: 40px 0 25px;
  }
  &--1 {
    @include mq("phablet","max") {
      padding: 25px 0 18px;
    }
  }
}

  .heading-primary {
    line-height: 1.2;
    position: relative;
    span {
      font-weight: 700;
      background-color: white;
      z-index: 12;
      font-size: 24px;
      padding: 0 10px;
      @include mq("phablet") {
        font-size: 26px;
      }
      @include mq("tablet") {
        font-size: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 65%;
        left: 0;
        height: 1.5px;
        width: 100%;
        background-color: $color-gray;
        z-index: -1;

        @include mq('phablet') {
          top: 50%;
        }
      }
    }
    &--page-heading {
      font-size: 25px;
      font-weight: bold;
      opacity: 0.9;
      color: $color-gray;
      @include mq('phablet','max') {
        font-size: 20px;
      }
      &:after {
        content: ""; 
        display: block;
        margin: 0 auto; 
        width: 5%; 
        padding-top: 10px;
        border-bottom: 1px solid $color-gray; 
        @include mq('tablet-wide','max') {
          width: 7%;
        }
        @include mq('tablet','max') {
          width: 10%;
        }
        @include mq('phablet','max') {
          width: 12%;
        }
        @include mq('phone-wide','max') {
          width: 16%;
        }
      }
    }
  }

//   .smalltext {
//     font-size: 14px;
//     display: block;
//     padding-top: 5px;

//     @include mq("tablet") {
//       font-size: 16px;
//     }
//   }
// }
.heading-secondary {
    font-size: 16px;
    font-weight: 500;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding-top: 3px;
 }
    // @include mq("tablet") {
    //   font-size: 16px;
    // }
}
.active {
  color: $color-primary;
}