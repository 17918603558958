.access-page {
    border-top: solid 1px $color-primary;

    &__desc {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: $color-gray;
        padding: 30px 0;

        @include mq('phablet', 'max') {
            font-size: 14px;
            padding: 15px 0;
        }
    }

    .container {
        max-width: 787px;
        margin-bottom: 75px;

        @include mq('phablet', 'max') {
            margin-bottom: 45px;
        }
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        @include mq('phablet', 'max') {
            margin-bottom: 0;
        }

        @include mq('tablet-port') {
            max-width: 770px;
        }
    }

    iframe {
        height: 412px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include mq('tablet', 'max') {
            height: 300px;
        }
    }
}