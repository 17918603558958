.company {
    margin-top: 45px;

    @include mq('tablet', 'max') {
        margin-top: 25px;
    }

    &__row {
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;

        @include mq('tablet', 'max') {
            padding-bottom: 0;
        }
    }

    &__inner-row {
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 5px;

        img {
            width: 45%;

            @include mq('tablet', 'max') {
                width: 100%;
            }

            @include mq('tablet') {
                height: 300px;
            }
        }
    }

    table {
        width: 100%;
        @include mq('tablet', 'max') {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }
    }

    table,
    th,
    td {
        border: 1px solid #CCCCCC;
        color: $color-gray;
    }

    td {
        padding: 5px 25px;
        vertical-align: middle;
        font-size: 16px;
        @include mq('phablet','max'){
            font-size: 14px;
        }
    }

    .bg-gray {
        background-color: rgba(185, 133, 233, 0.1);
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        font-weight: bold;
        color: $color-white;

        @include mq('tablet-port', 'max') {
            font-size: 16px;
        }

        @include mq('tablet', 'max') {
            font-size: 16px;
            background-color: $color-light-gray;
            ;
            width: 450px;
            height: 280px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: inherit;

        }

        @include mq('phone-wide', 'max') {
            width: auto;
        }
    }

    // &__bg {
    //     @include mq('tablet') {
    //         background-color: $color-light-gray;
    //     }
    // }

    &__inner-desc {
        font-size: 16px;
        line-height: 2;
        width: 55%;
        color: $color-gray;
        @include mq('tablet-port','max'){
            padding-right: 20px;
        }
        @include mq('tablet', 'max') {
            width: 100%;
            font-size: 14px;
            line-height: 1.5;
            padding: 10px 0;
            padding-right: 0;
        }
    }

    &__inner-desc--1 {
        padding-left: 25px;
        padding-right: 0;
        @include mq('tablet', 'max') {
            padding-left: 0;
        }
    }

    &__contact-us {
        margin-top: 55px;
    }

    .text-blue {
        color: #0051BC;
        text-decoration: underline;

        &:hover {
            color: #0051BC;
            text-decoration: underline;
        }
    }
}