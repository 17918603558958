.hero {
  // background-image: url(../img/business-card-1.jpg);
    background-image: url(../img/hero-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 30vh;
    position: relative;
    &--top{
      @include mq("phablet"){
        background-image: url(../img/hero-bg.jpg);
        height: 70vh;
      }
    }
    &--service-1{
      @include mq("phablet"){
        background-image: url(../img/services/service-hero-1.jpg);
        height: 45vh;
      }
    }
    &--service-2{
      @include mq("phablet"){
        background-image: url(../img/services/service-hero-2.jpg);
    height: 45vh;
      }
    }
    &--service-3{
      @include mq("phablet"){
        background-image: url(../img/services/service-hero-3.jpg);
    height: 45vh;
      }
    }
    &--service-4{
      @include mq("phablet"){
        background-image: url(../img/services/service-hero-4.jpg);
        height: 45vh;
      }
    }
    &--company{
      @include mq("phablet"){
        background-image: url(../img/hero-bg.jpg);
        height: 45vh;
      }
    }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: calc(100% - 5px);
    width: 100%;
    // opacity: -4;
    z-index: 0;
    background: rgba(0,0,0,0.1);

    @include mq('tablet-wide', 'max') {
      height: 98%;
    }

    @include mq('phablet', 'max') {
      background: rgba(0, 0, 0, 0.1);
      height: 100%;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 4;
    width: 100%;
    font-family: 'Noto Serif JP', serif;

    @include mq('tablet-port', 'max') {
      width: 90%;
    }
  }

  &__top-desc {
    font-size: 34px;
    color: $color-white;
    font-weight: bold;
    text-align: center;
    border-bottom: solid 1px $color-white;
    display: inline-block;

    // text-shadow: black 0.1em 0.1em 0.2em;
    margin-bottom: 24px;

    @include mq('tablet-wide', 'max') {
      font-size: 30px;
    }

    @include mq('tablet-small', 'max') {
      margin-bottom: 12px;
    }

    @include mq('phablet', 'max') {
      font-size: 20px;
      margin-bottom: 5px;
    }

    &--1 {
      font-size: 29px;
      border-bottom: none;

      @include mq('phablet', 'max') {
        font-size: 25px;
      }
    }
  }

  &__bottom-desc {
    text-align: center;
    font-size: 20px;
    color: $color-white;
    font-weight: bold;
    // text-shadow: black 0.1em 0.1em 0.2em;
    line-height: 1.8;

    @include mq('tablet-wide', 'max') {
      font-size: 22px;
    }

    @include mq('tablet-small', 'max') {
      line-height: 1.5;
    }

    @include mq('phablet', 'max') {
      font-size: 16px;
    }
  }

  &__btm-text {
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 4;
      width: 100%;
      padding-bottom: 15px;
      font-family: 'Noto Serif JP', serif;
  }

  picture img.img-fluid {
    width: 100%;
  }
}

.hero-img {
  @include mq('phablet', 'max') {
    display: none;
  }

  img {
    width: 100%;
  }
}