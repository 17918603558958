.our-business {
    .container {
        margin-top: 17px;
        margin-bottom: 22px;

        @include mq('tablet-wide') {
            max-width: 1054px;
            padding: 0 15px;
        }
    }

    &__text-box {
        height: 67px;
        background-color: $color-gray;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 31px;
        width: 100%;

        @include mq('phablet', 'max') {
            height: 50px;
            padding: 0 20px;
        }

        @include mq('phone-wide', 'max') {
            padding: 0 15px;
        }

        a {
            color: $color-white;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;

            @include mq('tablet', 'max') {
                font-size: 17px;
            }

            @include mq('phone-wide', 'max') {
                font-size: 15px;
            }
        }
    }

    &__section {
        overflow: hidden;

        &:hover {
            opacity: 0.7;
        }
    }

    &__img {
        position: relative;
        display: block;
        line-height: 0;
    }

    &__inner-down {
        line-height: 1.8;
        padding: 20px 18px;
        font-weight: 500;
        font-size: 16px;

        @include mq('tablet', 'max') {
            padding: 17px 15px;
            font-size: 15px;
        }

        @include mq('phone-wide', 'max') {
            padding: 13px 10px;
            font-size: 14px;
        }

    }
    .service-row{
        max-width: 800px;
        margin:0 auto;
    }
   
}

.businessflexbox {
    position: relative;
    border-left: 0;
    border-right: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &--1 {
        border-left: 1px solid #CCCCCC;
    }

    &--2 {
        @include mq('bootstrap-lg', 'max') {
            border-left: 1px solid #CCCCCC;
        }
    }

    &--3 {
        @include mq('bootstrap-lg', 'max') {
            margin-right: 5px;
        }
    }

    &--33 {
        @include mq('bootstrap-lg', 'max') {
            margin-left: 5px;
        }
    }

    &--4 {
        @include mq('bootstrap-lg', 'max') {
            margin-top: 10px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transform: scale(0, 0);
        transition: all 0.25s ease;
    }

    &__btn {
        word-break: keep-all;
        font-size: 20px;
        text-align: center;
        color: $color-white;
        padding: 5px 20px;
        border: 1px solid $color-white;
        display: inline-block;
        border-radius: 7px;
        text-decoration: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.25s ease;
        opacity: 0;
        z-index: 4;

        &:hover {
            text-decoration: none;
            color: $color-white;
        }
    }

    &:hover {
        &:after {
            opacity: 1;
            transform: scale(1, 1);
        }

        .businessflexbox__btn {
            opacity: 1;
        }
    }

}
.service-business{
    margin-top:40px;
   padding-top:40px;
   padding-bottom: 40px;
   background-color: rgba(239, 239, 239, 0.5);
    @include mq('phablet','max'){
        padding-top:20px;
        margin-top:20px;
        padding-bottom: 20px;
    }
}